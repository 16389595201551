import {
  BorderRadius,
  ButtonElement,
  CardElement,
  GradientElement,
  IconElement,
  ImageElement,
  ObjectFit,
  Position,
  RichTextElement,
  ShortTextElement,
} from '@BidftaStrapi/Elements/types'
import { ReactNode } from 'react'

export const componentMetadata = {
  Hero: 'Hero',
  IconGroup: 'IconGroup',
  IconImageSlider: 'IconImageSlider',
  Ad: 'Ad',
  List: 'List',
  EmailSignup: 'EmailSignup',
  Map: 'Map',
  ItemsForYou: 'ItemsForYou',
  SimpleCard: 'SimpleCard',
  Video: 'Video',
} as const

export type SectionComponent =
  | HeroComponent
  | IconsComponent
  | IconImageSliderComponent
  | ListComponent
  | AdComponent
  | MapComponent
  | EmailSignupComponent
  | ItemsForYou
  | VideoComponent

export type Orientation = 'center' | 'start' | 'end'

export interface VideoComponent extends BaseComponent {
  componentType: 'Video'
  videoUrl?: string
}

export interface HeroComponent extends BaseComponent {
  componentType: 'Hero'
  breakHeader?: ShortTextElement
  subtitle: ShortTextElement
  richBody?: RichTextElement
  button: ButtonElement
  button2: ButtonElement
  image?: {
    href?: string
    objectFit?: ObjectFit
    position?: Position
    borderRadius?: BorderRadius
    minHeight?: number
    src: ImageElement
  }
  horizontal?: Orientation
  vertical?: Orientation
  order?: 'row' | 'row-reverse'
}

export interface IconsComponent extends BaseComponent {
  componentType: 'IconGroup'
  icons: IconItem[]
}

export interface IconImageSliderComponent extends BaseComponent {
  componentType: 'IconImageSlider'
  subtitle: ShortTextElement
  imageItems: ImageItem[]
}

export interface AdComponent extends BaseComponent {
  componentType: 'Ad'
  title: ShortTextElement
  subtitle: ShortTextElement
  description: ShortTextElement
  primaryImage: ImageElement
  secondaryImage: ImageElement
  href: string
}

export interface SimpleCardComponent extends BaseComponent {
  componentType: 'CustomCard'
  title: ShortTextElement
  subtitle: ShortTextElement
  description: ShortTextElement
  image: ImageElement
  href: string
}

export interface MapComponent extends BaseComponent {
  componentType: 'Map'
}

export interface ItemsForYou extends BaseComponent {
  componentType: 'ItemsForYou'
  strings?: { id: number; string: string }[]
}

export interface EmailSignupComponent extends BaseComponent {
  componentType: 'EmailSignup'
}

export interface ListComponent extends BaseComponent {
  componentType: 'List'
  bulletList: ShortTextElement[]
  bulletPoint: IconElement
  subtitle: ShortTextElement
  button: ButtonElement
}

export type IconItem = {
  icon: IconElement
  name?: ShortTextElement
  description?: ShortTextElement
  href?: string
}

export type ImageItem = {
  image: ImageElement
  name?: ShortTextElement
  gradient?: GradientElement
  href?: string
}

export interface CardComponent extends CardElement {
  children?: ReactNode
}

export interface BaseComponent {
  span: number
  componentType:
    | 'Hero'
    | 'IconGroup'
    | 'Ad'
    | 'List'
    | 'EmailSignup'
    | 'Map'
    | 'ItemsForYou'
    | 'IconImageSlider'
    | 'CustomCard'
    | 'Video'
  title: ShortTextElement
  card: CardComponent
}
